import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { NonAuthRoutes } from "./url";
import { ReactComponent as LoadingIcon } from "./assets/svg/loadingIcon.svg";

const LandingPage = React.lazy(() => import("./pages/landingPage/landingPage"));
const Services = React.lazy(() => import("./pages/ourServices/services"));
const AboutUs = React.lazy(() => import("./pages/aboutUs/aboutUs"));
const ContactUs = React.lazy(() => import("./pages/contactUs/contactUs"));
const Blogs = React.lazy(() => import("./pages/ourBlogs/blogs"));
const BlogDetail = React.lazy(() => import("./pages/ourBlogs/blogDetails"));
const ProjectsGallery = React.lazy(() =>
  import("./pages/projectsGallery/projectsGallery")
);

function Routers() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="flex justify-center mt-60">
            <LoadingIcon className="btn-loading" />
          </div>
        }
      >
        <Routes>
          <Route path={NonAuthRoutes.landingPage} element={<LandingPage />} />
          <Route path={NonAuthRoutes.services} element={<Services />} />
          <Route path={NonAuthRoutes.aboutUs} element={<AboutUs />} />
          <Route path={NonAuthRoutes.contactUs} element={<ContactUs />} />
          <Route path={NonAuthRoutes.blogs} element={<Blogs />} />
          <Route path={NonAuthRoutes.blogDetail} element={<BlogDetail />} />
          <Route
            path={NonAuthRoutes.projectsGallery}
            element={<ProjectsGallery />}
          />
        </Routes>
      </Suspense>
    </div>
  );
}
export default Routers;
